const ApiUrls = {
    payment: () => `initiatePayment`,
    paymentHistory: (paymentId) => `payments/${paymentId}`,
    checkout: () => `paymentLink`,
    checkoutHistory: (paymentLinkId) => `paymentLink/${paymentLinkId}`,
    generateOTP: (number) => `verification/generateOTP/`+number,
    getSavedCards: (number,otp,environment) => `user/` + number +`/savedCard?otp=`+otp+`&environment=`+environment,
    getSavedCardsFromJwt: (number, environment) => `user/` + number +`/savedCard?environment=`+environment,
    getUserAddress: (number) => `user/` + number +`/address`,
    createUserAddress: (number) => `user/` + number +`/address`,
    updateUserAddress: (address_ref) => `user/address/`+address_ref,
    getTokenizationKey: () => `tokenization/key`,
    getCardToken: () => `v1/cards`,
    postCreditCards: () => `tokenization/tokenize-card`,
    getTxnRouter: (secretKey, environment) => `txn-router/`+secretKey+`/routes?environment=`+environment,
    postCaptureTxn: (order_ref) => `transaction/`+order_ref+`/capture`,
    postCardForCustomer: (customer_id) => `customer/`+customer_id+`/add-card`,
    deleteCardForCustomer: (customer_id) => `customer/`+customer_id+`/delete-card`,
    getCardForCustomer: (customer_id) => `customer/`+customer_id+`/list-cards`,
}

module.exports = ApiUrls;