// const stable = require("core-js/stable");
// const runtime = require("regenerator-runtime/runtime");

const AppSettings = require('./helpers/AppSettings');

const PaymentService = require('./api/PaymentService');

const CheckoutService = require('./api/CheckoutService');

const Environment = require('./helpers/Environment');


class ChaiPort {
    constructor({ chaiPayKey, env, debugMode = false, publicKey,jwtToken,subMerchantKey }) {

        // ChaiPay Key
        AppSettings.secretKey = chaiPayKey;
        AppSettings.jwtToken = jwtToken;
        
        // Optional - Once we add public key scheme
        AppSettings.publicKey = publicKey || chaiPayKey;

        // Environment Settings
        AppSettings.setEnvironment(env || Environment.PRODUCTION);
        AppSettings.debugMode = debugMode;
        // SubMerchant Key
        AppSettings.subMerchantKey = subMerchantKey;
        this.setupServices();
    }

    setupServices() {
        this.paymentService = new PaymentService();
        this.checkoutService = new CheckoutService();
    }
}

window.ChaiPay = ChaiPort;


class PortOne {
    constructor({ portOneKey, env, debugMode = false, publicKey,jwtToken,subMerchantKey }) {

        // ChaiPay Key
        AppSettings.secretKey = portOneKey;
        AppSettings.jwtToken = jwtToken;
        
        // Optional - Once we add public key scheme
        AppSettings.publicKey = publicKey || portOneKey;

        // Environment Settings
        AppSettings.setEnvironment(env || Environment.PRODUCTION);
        AppSettings.debugMode = debugMode;
        // SubMerchant Key
        AppSettings.subMerchantKey = subMerchantKey;
        this.setupServices();
    }

    setupServices() {
        this.paymentService = new PaymentService();
        this.checkoutService = new CheckoutService();
    }
}
window.PortOne = PortOne;
module.exports = {ChaiPay, PortOne};