const BaseService = require('./BaseService');
const ApiUrls = require('../helpers/ApiUrls');
const AppSettings = require('../helpers/AppSettings');

class CheckoutService extends BaseService {

    _checkout(payload) {
        return this.api.post(ApiUrls.checkout(), payload);
    }

    async _acheckout(payload, failurecb) {
        try {
            // console.log('base url', AppSettings.baseApiUrl)

            const response = await new Promise(resolve => {
                const res = fetch(`${AppSettings.baseApiUrl}/${ApiUrls.checkout()}`, {
                    method: "POST",
                    body: payload ? JSON.stringify(payload) : "",
                    headers: {
                        "Content-Type": "application/json",
                        "X-Chaipay-Client-Key":AppSettings.secretKey,
                        "Authorization": "Bearer "+AppSettings.jwtToken,
                    },
                })
                resolve(res)
            });

            const responseJson = await response.json();
            return responseJson;
        } catch (error) {
            throw error;
        }
    }

    async _checkoutFlow(payload, onFailure, onBeforeRedirect) {
        payload["chaipay_key"] = AppSettings.secretKey;
        payload["source"] = "checkout";
        var paymentLinkRef = ""
        try {
            // Call await paymentLink api
            const response = await this._acheckout(payload, onFailure);
            // console.log("payment link creation api call response", response)
            paymentLinkRef = response["payment_link_ref"]
            if(payload["override_auto_redirect"]){
                if (onBeforeRedirect) {
                    onBeforeRedirect(response);
                }
                return response
            }
            if (onBeforeRedirect) {
                onBeforeRedirect(response);
            }
        } catch (error) {
            throw error;
        }
        var queryParams = "";
        queryParams += "ref=" + paymentLinkRef

        const checkoutURL = AppSettings.checkoutURL;
        var redirectURL = checkoutURL + "?" + queryParams;

        // console.log("redirect url", redirectURL);

        if (payload["is_checkout_embed"] && window.screen.width > 768) {
            document.getElementById("portone-container").style.display = "block";
            document.getElementById('portone-checkout-frame').src = redirectURL;
        }else{
            window.location.href = redirectURL;
        }
    }

    checkout(payload, onFailure, onBeforeRedirect) {
        this._checkoutFlow(payload, onFailure, onBeforeRedirect);
    }


    getCheckoutHistory(paymentLinkId) {
        return this.api.get(ApiUrls.checkoutHistory(paymentLinkId));
    }
}

module.exports = CheckoutService;