const client = require('../node_modules/axios/dist/axios.js');
const AppSettings = require('../helpers/AppSettings');

class ApiHttpClient {
    constructor(baseUrl, apiKey, debug) {
        this.baseUrl = baseUrl;
        this.apiKey = apiKey;
        this.debug = debug;
    }


    _initConfig(url, method = 'GET', payload, customKey) {
        if(AppSettings.subMerchantKey){
            var config = {
                url: `${this.baseUrl}/${url}`,
                headers: {
                    'content-type': 'application/json',
                    'Authorization': 'Bearer ' + (customKey || AppSettings.jwtToken),
                    'X-Chaipay-SubMerchant-Key':AppSettings.subMerchantKey
                },
                method
            };
        }else{
            var config = {
                url: `${this.baseUrl}/${url}`,
                headers: {
                    'content-type': 'application/json',
                    'Authorization': 'Bearer ' + (customKey || AppSettings.jwtToken),
                },
                method
            };
        }

        if (payload) {
            config.data = payload;
            config.data.Key = (customKey || this.apiKey);
        }

        if (method === 'POST' && !config.data) {
            config.data = {
                "key": (customKey || this.apiKey)
            };
        }

        if (this.debug) {
            console.log('Payload Init Config', config);
        }

        // console.log('config', config)
        return config

    }

    sendRequest(url, method = 'GET', payload, customKey, successcb, failurecb) {
        let config = this._initConfig(url, method, payload, customKey);

        if (this.debug) {
            console.log('Send Request Payload Config', config);
        }

        return client.request(config)
            .then(response => {
                if (this.debug) {
                    console.log('response data', response);
                }

                if (successcb) {
                    successcb(response)
                }
                return response;

            })
            .catch(err => {
                if (this.debug) {
                    console.error('response error', err);
                }

                if (failurecb) {
                    failurecb(err)
                }
                throw err;

            })
    }

    get(url, customKey) {
        return this.sendRequest(url, 'GET', null, customKey);
    }

    post(url, payload, customKey, successcb, failurecb) {
        return this.sendRequest(url, 'POST', payload, customKey, successcb, failurecb);
    }

    put(url, payload, customKey) {
        return this.sendRequest(url, 'PUT', payload, customKey);
    }

    delete(url, customKey) {
        return this.sendRequest(url, 'DELETE', null, customKey);
    }

    // --------------------------------------------------
    // Async methods

    async awaitSendRequest(url, method = 'GET', payload, customKey, successcb, failurecb) {
        let config = this._initConfig(url, method, payload, customKey);

        if (this.debug) {
            console.log('Await Send Request Payload Config', config);
        }

        try {
            const response = await client.request(config);
            if (this.debug) {
                console.log('Await Send response data', response);
            }

            if (successcb) {
                successcb(response)
            }
            return response;


        } catch (error) {

            if (this.debug) {
                console.error('Await Send Response Error', error);
            }

            if (failurecb) {
                failurecb(error)
            }

            // throw err;
            return error.response

        }

    }

    async aget(url, payload, customKey, successcb, failurecb) {
        try {
            const response = await this.awaitSendRequest(url, 'GET', payload, customKey, successcb, failurecb);
            return response;

        } catch (error) {
            throw error;

        }

    }

    async apost(url, payload, customKey, successcb, failurecb) {
        try {
            const response = await this.awaitSendRequest(url, 'POST', payload, customKey, successcb, failurecb);
            return response;

        } catch (error) {
            throw error;

        }

    }

    async aput(url, payload, customKey, successcb, failurecb) {
        try {
            const response = await this.awaitSendRequest(url, 'PUT', payload, customKey, successcb, failurecb);
            return response;

        } catch (error) {
            throw error;

        }

    }

    async adelete(url, payload, customKey, successcb, failurecb) {
        try {
            const response = await this.awaitSendRequest(url, 'DELETE', payload, customKey, successcb, failurecb);
            return response;

        } catch (error) {
            throw error;

        }

    }

}

module.exports = ApiHttpClient;